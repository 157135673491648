const { default: axios } = require('axios');
const inputmask = require('inputmask');
const { default: Swal } = require('sweetalert2');


require('./bootstrap');

document.addEventListener('DOMContentLoaded', () => {
    // STORE
    // |
    // |_ PRODUCTS
    const products = document.querySelectorAll('.product');
    if (products) {
        products.forEach((product) => {
            product.addEventListener('click', () => {
                let productId = product.firstElementChild.value;
                axios.post('/add-product', { productId })
                    .then((response) => {
                        if (response.data.status === 'OK') {
                            window.location = `/tamanhos/${productId}`;
                        } else {
                            alert("error")
                        }
                    });
            });
        });
    }

    // |_ SIZES
    const sizes = document.querySelectorAll('.size');
    if (sizes) {
        sizes.forEach((size) => {
            size.addEventListener('click', () => {
                let productId = size.children[0].value;
                let sizeId = size.children[1].value;

                axios.post('/add-size', { productId, sizeId })
                    .then((response) => {
                        if (response.data.status === 'OK') {
                            window.location = `/opcoes/${productId}`;
                        } else {;
                            alert("error")
                        }
                    });
            });
        });
    }

    // |_ OPTIONS
    const options = document.querySelectorAll('.option');
    if (options) {
        options.forEach((option) => {
            option.addEventListener('click', () => {
                let productId = document.querySelector('#pid').value;
                let optionId = option.firstElementChild.value;
                let nextElement = option.firstElementChild.nextElementSibling;
                let extraOption = null;

                if (nextElement.className == 'extra-option') {
                    Swal.fire({
                        title: nextElement.getAttribute('title'),
                        text: nextElement.getAttribute('desc'),
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        extraOption = result.isConfirmed
                            ? 1 : 0;

                        axios.post('/add-option', { productId, optionId, extraOption })
                            .then((response) => {
                                if (response.data.status === 'OK') {
                                    window.location = `/resumo`;
                                }
                            });
                    })
                } else {
                    axios.post('/add-option', { productId, optionId, extraOption })
                        .then((response) => {
                            if (response.data.status === 'OK') {
                                window.location = `/resumo`;
                            }
                        });
                }

            });
        });
    }

    // |_ SUMMARY
    const doneBtn = document.querySelector('#done-btn');
    if (doneBtn) {
        doneBtn.addEventListener('click', (e) => {
            e.preventDefault();
            let done = document.querySelector('#done');

            done.value = done.value === '1' ? '' : '1';

            document.querySelector('#summary-form').submit();
        });
    }

    // |_ CART DELETE MODAL
    const deleteItemBtn = document.querySelectorAll('.cart-btn-delete');
    if (deleteItemBtn) {
        deleteItemBtn.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();

                Swal.fire({
                    title: 'Remover Produto',
                    text: "Você tem certeza que deseja remover este item?",
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonColor: '#23c55e',
                    cancelButtonColor: '#e94649',
                    confirmButtonText: 'Sim'
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire(
                            'Item removido',
                            'Item removido com sucesso.',
                            'success'
                        ).then(() => {
                            item.parentElement.submit();
                        });
                    }
                });
            });
        });
    }

    // MASKS
    Inputmask({
        mask: '99999-999'
    }).mask(document.querySelectorAll('.mask-cep'));

    Inputmask({
        mask: '(99) 99999-9999'
    }).mask(document.querySelectorAll('.mask-phone'));

    // DELIVERY
    const retirada = document.querySelector("#retirada");
    const paymentMethods = document.querySelector('#payment-methods');
    const creditCardOption = document.querySelector('[value="3"]');
    if (retirada) {
        retirada.addEventListener("click", () => {
            let inputs = document.querySelectorAll('[class*="delivery"]');
            if (retirada.checked) {
                creditCardOption.setAttribute('disabled', true);
                if (paymentMethods.value === 'card') {
                    paymentMethods.value = '';
                }
                document.querySelector('#payment-methods').value = '';
                inputs.forEach(input => input.removeAttribute('required', false));
                document.querySelector('#retirada-note').classList.remove('hidden');
                document.querySelector("#address").classList.add('hidden');
            } else {
                creditCardOption.removeAttribute('disabled');
                inputs.forEach(input => input.setAttribute('required', true));
                document.querySelector('#retirada-note').classList.add('hidden');
                document.querySelector("#address").classList.remove('hidden');
            }
        });

        paymentMethods.addEventListener('change', () => {
            if (paymentMethods.value === '1') {
                document.querySelector('#payment-obs').innerText = 'Chave Pix: 21964693424';
            } else {
                document.querySelector('#payment-obs').innerText = '';
            }
        });
    }

    const city = document.querySelector('#city');
    if (city) {
        city.addEventListener('change', (e) => {
            let id = e.target.value;

            fetch('/get-fee', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('input[name="_token"]').value
                },
                body: JSON.stringify({ id })
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let info = document.querySelector('#info');

                if (data.fee) {
                    info.innerText =
                        `Taxa de entrega para ${data.name}: ${data.formated_fee}.`;
                } else {
                    info.innerText =
                        `Isento de taxa de entrega!`;
                }
            })
            .catch((error) => {
                console.error(error);
            });
        });
    }
});
